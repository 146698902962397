import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const RecommendationItem = ({ icon, heading, description, link }) => {
  const content = <p dangerouslySetInnerHTML={{ __html: description }} />;

  const isExternalLink = link && link.indexOf("travel-lingual.com") === -1;
  const shouldFollowLink =
    link && (link.startsWith("FOLLOW - ") || link.startsWith("FOLLOW -"));
  const modifiedLink =
    link && shouldFollowLink ? link.replace(/^FOLLOW\s*-\s*/i, "") : link;
  const relAttribute = shouldFollowLink ? "follow" : "nofollow";

  return (
    <div className="recommendation-box-inner">
      <div className="recommendation-heading">
        <p>{heading}</p>
      </div>
      <div className="recommendation-content">
        <FontAwesomeIcon
          icon={icon}
          style={{ color: "#000", fontSize: 20, marginRight: 10 }}
        />{" "}
        {link ? (
          <a
            href={modifiedLink}
            target={isExternalLink ? "_blank" : ""}
            rel={relAttribute}
          >
            {content}
          </a>
        ) : (
          content
        )}
      </div>
    </div>
  );
};

export default RecommendationItem;
