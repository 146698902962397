import * as React from "react";
import clsx from "clsx";
import { Link } from "gatsby";

import "./ArticleBanner.css";

export default function ArticleBanner({
  bannerText,
  bannerUrl,
  bannerCtaText,
}) {
  return (
    <div className="article-banner">
      <div
        dangerouslySetInnerHTML={{
          __html: bannerText.childMarkdownRemark.html,
        }}
      />
      <a href={bannerUrl}>
        <div className="article-banner-cta">{bannerCtaText}</div>
      </a>
    </div>
  );
}
