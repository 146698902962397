import React from "react";

const removeScriptFromHTML = (htmlString) => {
  // Use a regular expression to remove the script element
  const cleanedHTML = htmlString.replace(
    /<script\s+async\s+src="https:\/\/www\.tiktok\.com\/embed\.js"><\/script>/i,
    ""
  );

  return cleanedHTML;
};

const TikTokWidget = ({ code }) => {
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: removeScriptFromHTML(code) }} />
    </div>
  );
};

export default TikTokWidget;
