import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownLong } from "@fortawesome/free-solid-svg-icons";

import "./TourPlan.css";

const parseHtmlList = (html) => {
  // Remove <p> tags and split the HTML content by line breaks
  const strippedHtml = html.replace(/<p>|<\/p>/g, "");
  const lines = strippedHtml.split("\n").filter((line) => line.trim() !== "");

  return lines;
};

const findImageNextToHeading = (html, headingText) => {
  // Regular expressions to match <h2> and <img> tags
  const h2Regex = /<h2[^>]*>(.*?)<\/h2>/gi;
  const imgRegex = /<p><img src="(.+?)" alt="(.+?)"[^>]*><\/p>/gi;

  let h2Matches = [...html.matchAll(h2Regex)];
  let imgMatches = [...html.matchAll(imgRegex)];

  let foundImage = null;

  for (let i = 0; i < h2Matches.length; i++) {
    const h2Match = h2Matches[i];
    const [, heading] = h2Match;

    // Extract the actual text within the <h2> tag (remove leading number and period)
    const actualHeadingText = heading
      .replace(/^\d+\.\s*/, "")
      .replace(/<\/?[^>]+(>|$)/g, "");

    // Check if the extracted heading text matches the target heading
    if (actualHeadingText === headingText) {
      // Check if there's an <img> tag following the matching <h2>

      if (i < imgMatches.length) {
        const imgMatch = imgMatches[i + 1];

        if (imgMatch) {
          const [, imgSrc, imgAlt] = imgMatch;

          foundImage = { src: imgSrc, alt: imgAlt };
        }
      }
      break; // Stop searching after finding a match
    }
  }

  return foundImage;
};

const TourPlan = ({ html, items }) => {
  const itemList = parseHtmlList(items);

  return (
    <div className="tour-plan">
      <h2
        style={{
          width: "100%",
          textAlign: "center",
          marginBottom: 0,
          color: "rgb(235 123 19)",
        }}
      >
        Tour plan
      </h2>
      {itemList.map((item, index) => (
        <React.Fragment key={index}>
          <TourItem day={item} image={findImageNextToHeading(html, item)} />
          {index < itemList.length - 1 && (
            <FontAwesomeIcon icon={faDownLong} className="angle-down-icon" />
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

const TourItem = ({ day, image }) => {
  return (
    <div className="tour-item">
      {image && <img src={image.src} alt={day} style={{ width: 70 }} />}
      <h4>{day}</h4>
    </div>
  );
};

export default TourPlan;
