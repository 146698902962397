import React, { Component } from "react";

const removeScriptFromHTML = (htmlString) => {
  // Use a regular expression to remove the script element
  const cleanedHTML = htmlString.replace(
    /<script.*src=["'][^"']*https:\/\/www\.viator\.com\/orion\/partner\/widget\.js[^"']*["'][^>]*><\/script>/i,
    ""
  );

  return cleanedHTML;
};

const ViatorWidget = ({ code }) => {
  return (
    <div>
      <div dangerouslySetInnerHTML={{ __html: removeScriptFromHTML(code) }} />
    </div>
  );
};

export default ViatorWidget;
