const pickRandomItems = (array, numItems) => {
  if (numItems > array.length) {
    numItems = array.length;
    //   throw new Error("Number of items requested is greater than array length.");
  }

  const shuffledArray = array.slice().sort(() => 0.5 - Math.random());
  return shuffledArray.slice(0, numItems);
};

module.exports = { pickRandomItems };
