import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import Section from "../Section/Section";
import SearchResultCarousel from "../SearchResultCarousel/SearchResultCarousel";
import LatestArticle from "../LatestArticle/LatestArticle";
import SectionHeading from "../SectionHeading/SectionHeading";

const RelatedArticles = ({ title, relatedArticles }) => {
  // Track unique slugs
  const uniqueSlugs = new Set();

  return (
    <Section>
      <div className="latest-articles-heading">
        <SectionHeading component="h2">
          {title ? title.toUpperCase() : "RELATED ARTICLES"}
        </SectionHeading>
      </div>
      <SearchResultCarousel
        slides={
          relatedArticles &&
          relatedArticles
            .filter((article) => {
              if (!article.node.hideFromHomePage) {
                // Check if the slug is unique, if not, skip this article
                if (!uniqueSlugs.has(article.node.slug)) {
                  uniqueSlugs.add(article.node.slug);
                  return true;
                }
              }
              return false;
            })
            .map((article) => (
              <LatestArticle
                key={article.node.slug}
                latestArticle={article.node}
              />
            ))
        }
      />
    </Section>
  );
};

export default RelatedArticles;
