import React, { useState, useEffect } from "react";
import { useLocation } from "@reach/router";
import Helmet from "react-helmet";
import { Link, graphql, useStaticQuery } from "gatsby";
import { config } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faBurger,
  faMartiniGlassCitrus,
  faBed,
  faLandmark,
  faWarning,
  faMoneyBill,
  faCalendar,
  faCloud,
  faBus,
  faStar,
} from "@fortawesome/free-solid-svg-icons";
import "@fortawesome/fontawesome-svg-core/styles.css";

import { SEO } from "../components/seo";
import Page from "../components/Page/Page";
import TopBar from "../components/TopBar/TopBar";
import Section from "../components/Section/Section";
import SectionHeading from "../components/SectionHeading/SectionHeading";
import ArticleBanner from "../components/ArticleBanner/ArticleBanner";
import SubscribeForm from "../components/SubscribeForm/SubscribeForm";
import FeaturedIn from "../components/FeaturedIn/FeaturedIn";
import Footer from "../components/Footer/Footer";
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs";
import RelatedArticles from "../components/RelatedArticles/RelatedArticles";
import RecommendationItem from "../components/RecommendationItem/RecommendationItem";
import TourPlan from "../components/TourPlan/TourPlan";
import ViatorWidget from "../components/ViatorWidget/ViatorWidget";
import TikTokWidget from "../components/TikTokWidget/TikTokWidget";
import FacebookComments from "../components/FacebookComments/FacebookComments";

import "./ArticlePage.css";

import { getActivitiesByCity as apiGetActivitiesByCity } from "../services/api";

import { GlobalStateContext } from "../context/GlobalContextProvider";

import YoutubeIcon from "../images/youtube-3-xl.png";
import LinkedInIcon from "../images/linkedin-3-xl.png";
import TikTokIcon from "../images/tiktok-2-xxl.png";

import authors from "../../authors.json";

config.autoAddCss = false;

const utils = require("../services/utils");

const createSlug = (title) => {
  const slug = title.replace(/\W+/g, "-").toLowerCase();
  return slug;
};

export const query = graphql`
  query ($articleId: String!) {
    contentfulArticle(id: { eq: $articleId }) {
      id
      title
      description {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
      youtubeLink
      showBanner
      bannerText {
        childMarkdownRemark {
          html
        }
      }
      city
      bannerCtaText
      bannerUrl
      thumbnailImageUrl
      content {
        childMarkdownRemark {
          html
        }
      }
      author
      createdAt
      updatedAt
      mostRecommendedThingToDo
      topChoiceRestaurantForFoodLovers
      topChoiceBarForNightlife
      mostRecommendedThingUrl
      topChoiceHotelUrl
      topChoiceRestaurantUrl
      topChoiceBarUrl
      sourceUrl
      sourceLogoUrl
      whatNotToMiss {
        childMarkdownRemark {
          html
        }
      }
      topChoiceHotel
      whatToAvoid {
        childMarkdownRemark {
          html
        }
      }
      bestTimeToVisit {
        childMarkdownRemark {
          html
        }
      }
      transportationOptions {
        childMarkdownRemark {
          html
        }
      }
      averageTemperatureInPlace {
        childMarkdownRemark {
          html
        }
      }
      cost
      authorBio {
        childMarkdownRemark {
          html
        }
      }
      myRecommendation {
        childMarkdownRemark {
          html
        }
      }
      thingsYoullNeedToBring {
        childMarkdownRemark {
          html
        }
      }
      tourPlan {
        childMarkdownRemark {
          html
        }
      }
      bannerText {
        childMarkdownRemark {
          html
        }
      }
      viatorSideWidget {
        childMarkdownRemark {
          html
        }
      }
      viatorBottomWidget {
        childMarkdownRemark {
          html
        }
      }
      tiktokSideWidget {
        childMarkdownRemark {
          html
        }
      }
      followLinks {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;

const capitalizeHeading = (heading) => {
  const lowercaseWords = ["to", "for", "and", "in", "of", "the"]; // Add more words as needed
  const words = heading.split(" ");

  const capitalizedWords = words.map((word) => {
    if (lowercaseWords.includes(word.toLowerCase())) {
      return word.toLowerCase();
    } else {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }
  });

  return capitalizedWords.join(" ");
};

function removeAfterPipeInHTML(inputString) {
  // Split the inputString at the "|" character
  const parts = inputString.split("|");

  // Take the first part (before the "|") and trim any leading/trailing whitespace
  const result = parts[0].trim();

  return result;
}

const getHeading = (html) => {
  let h1Content = "";

  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    h1Content = group;
    return ""; // Remove the h1 tag from the HTML
  });

  return h1Content;
};

const getContentBeforeFirstH2 = (html) => {
  const result = {
    image: "",
    content: "",
  };

  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    return ""; // Remove the h1 tag from the HTML
  });

  // Use a regular expression to match content up to the first <h2> tag
  const regex = /([\s\S]*?)<h2\b[^>]*>/;
  const match = regex.exec(html);

  if (match) {
    // match[1] contains the content up to the first <h2> tag
    result.content = match[1];
  }

  // Add rel="nofollow" and target="_blank" to <a> tags
  result.content = result.content.replace(
    /<a\s+(?![^>]*rel=['"]nofollow['"])(?![^>]*href=['"](https:\/\/travel-lingual\.com\/)([^"']*))([^>]*)>/gi,
    '<a $3 rel="nofollow" target="_blank">'
  );

  // // Add loading="lazy" to <img> tags except the first 3
  // let imageCount = 0;

  // result.content = result.content.replace(
  //   /<img\s+(?![^>]*loading=['"]lazy['"])([^>]*)>/gi,
  //   (match, group) => {
  //     if (imageCount < 1) {
  //       const srcMatch = /src=["'](.*?)["']/i.exec(group);
  //       const imageUrl = srcMatch[1];

  //       // Call the transformImageUrl method to modify the image URL
  //       const transformedImageUrl = transformImageUrl(imageUrl, 790, 300);

  //       // Replace the original src attribute with the transformed image URL
  //       group = group.replace(
  //         /src=["'](.*?)["']/i,
  //         `src="${transformedImageUrl}"`
  //       );

  //       result.image = `<img ${group} class="my-recommendation-image">`;
  //     } else {
  //       result.content += `<img ${group} loading="lazy" style="height: auto; width: 100%; aspect-ratio: 16 / 11; object-fit: contain;">`;
  //     }
  //     imageCount++;
  //     return ""; // Remove the original image tag
  //   }
  // );

  return result;
};

const transformImageUrl = (imageUrl, width = 120, height = 80) => {
  // Check if the URL already contains parameters
  if (imageUrl.includes("?")) {
    // If parameters are already present, add the new parameters with '&'
    return `${imageUrl}&tr=w-${width},h-${height},fo-auto`;
  } else {
    // If no parameters are present, add the new parameters with '?'
    return `${imageUrl}?tr=w-${width},h-${height},fo-auto`;
  }
};

const formatHtml = (html, followLinks) => {
  function replaceLinks(html) {
    // Regular expression to match <a> tags
    var regex = /<a\s+(.*?)>/gi;

    // Function to handle each match found
    function replaceCallback(match, attributes) {
      // Check if href attribute exists
      console.log("FOLLOW LINKS", followLinks);
      if (attributes.includes("href=")) {
        // Regular expression to match href attribute
        var hrefRegex = /href=['"](.*?)['"]/i;
        var hrefMatch = attributes.match(hrefRegex);
        if (hrefMatch && hrefMatch[1]) {
          var href = hrefMatch[1];
          if (href.indexOf("travel-lingual.com") === -1) {
            if (followLinks && followLinks.indexOf(href) !== -1) {
              console.log("match", match);
              var relRegex = /rel=['"](.*?)['"]/i;
              var relMatch = attributes.match(relRegex);
              if (relMatch && relMatch[1]) {
                // If rel attribute exists, replace it with "nofollow" and add "target=_blank"
                return match.replace(relRegex, 'target="_blank"');
              } else {
                // If rel attribute doesn't exist, add "rel=nofollow" and "target=_blank"
                return match.replace(/<a\s+(.*?)>/i, '<a $1 target="_blank">');
              }
            } else {
              console.log("match", match);
              var relRegex = /rel=['"](.*?)['"]/i;
              var relMatch = attributes.match(relRegex);
              if (relMatch && relMatch[1]) {
                // If rel attribute exists, replace it with "nofollow" and add "target=_blank"
                return match.replace(
                  relRegex,
                  'rel="nofollow" target="_blank"'
                );
              } else {
                // If rel attribute doesn't exist, add "rel=nofollow" and "target=_blank"
                return match.replace(
                  /<a\s+(.*?)>/i,
                  '<a $1 rel="nofollow" target="_blank">'
                );
              }
            }
          }
        }
      }
      // If condition doesn't match, return the original match
      return match;
    }

    // Perform the replacement
    return html.replace(regex, replaceCallback);
  }

  html = html.replace(/<h1>(.*?)<\/h1>/i, (match, group) => {
    return ""; // Remove the h1 tag from the HTML
  });

  // Use a regular expression to match content up to the first <h2> tag
  const regex = /(<h2\b[^>]*>[\s\S]*?<\/h2>)([\s\S]*)/;
  const match = regex.exec(html);

  if (match) {
    // match[1] contains the content up to the first <h2> tag
    html = match[0];
  }

  html = html.replace(
    /<img\s+(?![^>]*loading=['"]lazy['"])([^>]*)>/gi,
    (match, group) => {
      return `<img ${group} loading="lazy" style="height: auto; width: 100%; aspect-ratio: 16 / 11; object-fit: contain;">`;
    }
  );

  // Add id attribute to all h2 tags
  html = html.replace(/<h2>(.*?)<\/h2>/gi, (match, group) => {
    var aTagRegex = /<a[^>]*>.*?<\/a>/g;

    // Create an id based on the text content
    const id = group
      .replace(aTagRegex, "")
      .toLowerCase()
      .replace(/ /g, "-")
      .replace(/[^\w-]/g, ""); // Remove punctuation

    // Add the id attribute to the h2 tag
    return `<h2 id="${id}">${group.replace(aTagRegex, "")}</h2>`;
  });

  html = replaceLinks(html, followLinks);

  return html;
};

const generateTableOfContents = (html) => {
  const tableOfContents = [];
  let currentEntry = null; // Track the current entry being processed

  // Regular expressions to match <h2> and <img> tags
  const h2Regex = /<h2>(.*?)<\/h2>/gi;
  const imgRegex =
    /<p>(?:(?:<strong>)*<img src="(.+?)"(?: alt="(.+?)")?(?:\/?>)(?:<\/strong>)*)<\/p>/g;

  // Iterate through matches in the HTML
  let h2Matches = [...html.matchAll(h2Regex)];
  let imgMatches = [...html.matchAll(imgRegex)];

  imgMatches.shift();

  if (imgMatches.length < 5) {
    imgMatches = [];
  }

  while (h2Matches.length > 0 || imgMatches.length > 0) {
    // Check if there's an <h2> entry
    if (h2Matches.length > 0) {
      const [h2Match] = h2Matches;
      const [, group] = h2Match;

      if (
        group.toLowerCase().indexOf("summing up") === -1 &&
        group.indexOf("FAQ") === -1
      ) {
        var aTagRegex = /<a[^>]*>.*?<\/a>/g;

        // Create an id based on the text content
        const idCleaned = group
          .replace(aTagRegex, "")
          .toLowerCase()
          .replace(/ /g, "-")
          .replace(/[^\w-]/g, "");
        // Generate the table of contents entry and set it as the current entry
        currentEntry = { id: idCleaned, text: group.replace(aTagRegex, "") };
        tableOfContents.push(currentEntry);

        // Remove the processed <h2> match
        h2Matches.shift();
      } else {
        h2Matches.shift();
      }
    }

    // Check if there's an <img> entry
    if (imgMatches.length > 0) {
      const [imgMatch] = imgMatches;

      if (imgMatch) {
        const [, imgSrc, imgAlt] = imgMatch;

        // Add the image information to the current entry if available
        if (currentEntry) {
          currentEntry.imgSrc = transformImageUrl(
            imgSrc.replace('" alt="', "")
          );

          // Check if imgAlt is defined and not empty, then trim it
          if (imgAlt && imgAlt.trim()) {
            currentEntry.imgAlt = imgAlt.trim();
          } else {
            // currentEntry.imgAlt = "Default Alt Text";
          }
        }

        // Remove the processed <img> match
        imgMatches.shift();
      }
    }
  }

  return tableOfContents;
};

const ArticleTemplate = ({ pageContext, data }) => {
  const location = useLocation();

  const context = React.useContext(GlobalStateContext);

  const [selectedItemId, setSelectedItemId] = useState("");
  const [showAll, setShowAll] = useState(false);

  const handleViewMoreClick = () => {
    setShowAll(true);
  };

  const { city, relatedArticles } = pageContext;
  const {
    id,
    content,
    youtubeLink,
    showBanner,
    bannerText,
    bannerUrl,
    bannerCtaText,
    city: articleCity,
    createdAt,
    updatedAt,
    author,
    mostRecommendedThingToDo,
    topChoiceRestaurantForFoodLovers,
    topChoiceBarForNightlife,
    whatNotToMiss,
    topChoiceHotel,
    whatToAvoid,
    bestTimeToVisit,
    transportationOptions,
    averageTemperatureInPlace,
    cost,
    authorBio,
    myRecommendation,
    thingsYoullNeedToBring,
    tourPlan,
    viatorSideWidget,
    viatorBottomWidget,
    tiktokSideWidget,
    mostRecommendedThingUrl,
    topChoiceHotelUrl,
    topChoiceRestaurantUrl,
    topChoiceBarUrl,
    sourceUrl,
    sourceLogoUrl,
    followLinks,
  } = data.contentfulArticle;

  const [cityActivities, setCityActivities] = React.useState([]);
  const [loadingCityActivities, setLoadingCityActivities] =
    React.useState(false);
  const [showDynamicContent, setShowDynamicContent] = useState(false);

  const getActivitiesByCity = async () => {
    // setErrors([]);

    try {
      setLoadingCityActivities(true);

      var categories = "";
      var minPrice = 0;
      var maxPrice = 450;

      const data = await apiGetActivitiesByCity(
        city.id,
        context.currentCurrency,
        categories,
        minPrice,
        maxPrice
      );

      if (data) {
        setCityActivities(data);
      }
      setLoadingCityActivities(false);
    } catch (e) {
      console.log(e);
      setLoadingCityActivities(false);
    }
  };

  React.useEffect(() => {
    if (city) {
      getActivitiesByCity();
    }
  }, [city, context.currentCurrency]);

  let countryPath = "";
  let regionPath = "";
  let cityPath = "";

  if (city) {
    countryPath = `/${createSlug(city.country.name)}`;

    if (city.regionCities && city.regionCities.length > 0) {
      regionPath = `/${createSlug(
        city.country.name
      )}/${city.regionCities[0].region.slug.replace(
        `-${createSlug(city.country.name)}`,
        ""
      )}`;
    }

    cityPath = `/${createSlug(city.country.name)}/${city.slug.replace(
      `-${createSlug(city.country.name)}`,
      ""
    )}`;

    if (city.regionCities && city.regionCities.length > 0) {
      cityPath = `/${createSlug(
        city.country.name
      )}/${city.regionCities[0].region.slug.replace(
        `-${createSlug(city.country.name)}`,
        ""
      )}/${city.slug.replace(`-${createSlug(city.country.name)}`, "")}`;
    }
  }

  useEffect(() => {
    // Use a delay or any other condition to control when the video should load.
    // For example, you can load it after a certain amount of time or when a user interacts with it.
    const timeout = setTimeout(() => {
      setShowDynamicContent(true);
    }, 3000); // Load the video after 3 seconds (adjust this delay as needed)

    // Clean up the timeout to avoid memory leaks
    return () => clearTimeout(timeout);
  }, []);

  const handleDropdownChange = (event) => {
    setSelectedItemId(event.target.value);
  };

  useEffect(() => {
    if (selectedItemId) {
      const selectedSection = document.getElementById(selectedItemId);
      if (selectedSection) {
        selectedSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [selectedItemId]);

  const tableOfContents = generateTableOfContents(
    content.childMarkdownRemark.html
  );

  // Format the date as you desire (for example, in a human-readable format)
  var options = { year: "numeric", month: "long", day: "numeric" };
  const formattedDate = new Date(createdAt).toLocaleDateString(
    "en-US",
    options
  ); // Change the format as needed

  const h1Content = getHeading(content.childMarkdownRemark.html);

  const descriptionContent = getContentBeforeFirstH2(
    content.childMarkdownRemark.html
  );

  const authorProfile = authors.find((_) => author && _.name === author[0]);

  return (
    <Page>
      {showDynamicContent && (
        <Helmet>
          <script
            async
            defer
            src="https://widgets.tiqets.com/loader.js"
          ></script>
          <script
            async
            defer
            src="https://www.viator.com/orion/partner/widget.js"
          ></script>
          <script async defer src="https://www.tiktok.com/embed.js"></script>
        </Helmet>
      )}
      <TopBar showSearch={true} />
      <Section>
        <div className="article">
          <div className="article-content">
            {city && (
              <Breadcrumbs>
                <a href="/">Home</a>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ color: "#000", fontSize: 15 }}
                />
                <a href={countryPath}>{city.country.name}</a>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ color: "#000", fontSize: 15 }}
                />
                {city.regionCities && city.regionCities.length > 0 && (
                  <>
                    <a href={regionPath}>{city.regionCities[0].region.name}</a>
                    <FontAwesomeIcon
                      icon={faChevronRight}
                      style={{ color: "#000", fontSize: 15 }}
                    />
                  </>
                )}
                <a href={cityPath}>
                  {city.name.replace(`, ${city.country.name}`, "")}
                </a>
                <FontAwesomeIcon
                  icon={faChevronRight}
                  style={{ color: "#000", fontSize: 15 }}
                />
                <div
                  dangerouslySetInnerHTML={{
                    __html: removeAfterPipeInHTML(h1Content),
                  }}
                  className="current-page"
                />
              </Breadcrumbs>
            )}
            {h1Content && (
              <h1 dangerouslySetInnerHTML={{ __html: h1Content }} />
            )}
            {authorProfile && (
              <div className="author-bio">
                <div>
                  <Link to={`/author/${authorProfile.name.toLowerCase()}`}>
                    <img
                      src={authorProfile.image}
                      alt={authorProfile.fullName}
                      className="author-photo"
                    />
                  </Link>
                  {sourceLogoUrl && sourceUrl ? (
                    <a href={sourceUrl} target="_blank">
                      <img src={sourceLogoUrl} className="source-logo" />
                    </a>
                  ) : (
                    <img src={sourceLogoUrl} className="source-logo" />
                  )}
                </div>
                <div className="author-info">
                  <h4 className="author-name">
                    <Link to={`/author/${authorProfile.name.toLowerCase()}`}>
                      <span>{authorProfile.fullName}</span>
                    </Link>
                    <span className="author-role">
                      {" - "}
                      {authorProfile.role}
                    </span>
                  </h4>
                  <p className="author-last-updated">
                    Published: {formattedDate}
                  </p>
                  {authorBio && (
                    <p
                      className="author-bio-text"
                      dangerouslySetInnerHTML={{
                        __html: authorBio.childMarkdownRemark.html,
                      }}
                    />
                  )}
                </div>
              </div>
            )}
            <div
              dangerouslySetInnerHTML={{
                __html: descriptionContent.content,
              }}
            />
            <div className="recommendation-container">
              <div className="recommendation-box">
                {mostRecommendedThingToDo && (
                  <RecommendationItem
                    icon={faLandmark}
                    heading={capitalizeHeading("Most recommended thing to do")}
                    description={mostRecommendedThingToDo}
                    link={mostRecommendedThingUrl}
                  />
                )}
                {topChoiceHotel && (
                  <RecommendationItem
                    icon={faBed}
                    heading={capitalizeHeading("Top choice hotel")}
                    description={topChoiceHotel}
                    link={topChoiceHotelUrl}
                  />
                )}
                {topChoiceRestaurantForFoodLovers && (
                  <RecommendationItem
                    icon={faBurger}
                    heading={capitalizeHeading("Our top choice restaurant")}
                    description={topChoiceRestaurantForFoodLovers}
                    link={topChoiceRestaurantUrl}
                  />
                )}
                {topChoiceBarForNightlife && (
                  <RecommendationItem
                    icon={faMartiniGlassCitrus}
                    heading={capitalizeHeading(
                      "Our top choice bar for nightlife"
                    )}
                    description={topChoiceBarForNightlife}
                    link={topChoiceBarUrl}
                  />
                )}
                {bestTimeToVisit && (
                  <RecommendationItem
                    icon={faCalendar}
                    heading={capitalizeHeading("Best Time to Visit")}
                    description={bestTimeToVisit.childMarkdownRemark.html}
                  />
                )}
                {averageTemperatureInPlace && (
                  <RecommendationItem
                    icon={faCloud}
                    heading={capitalizeHeading("Average Temperature")}
                    description={
                      averageTemperatureInPlace.childMarkdownRemark.html
                    }
                  />
                )}
                {transportationOptions && (
                  <RecommendationItem
                    icon={faBus}
                    heading={capitalizeHeading("Transportation Options")}
                    description={transportationOptions.childMarkdownRemark.html}
                  />
                )}
                {cost && (
                  <RecommendationItem
                    icon={faMoneyBill}
                    heading={capitalizeHeading("Average Cost ($, $$, $$$)")}
                    description={cost}
                  />
                )}
              </div>
            </div>
            {myRecommendation && (
              <Section style={{ padding: "20px 0" }}>
                <SectionHeading component="h2">
                  {capitalizeHeading("My top recommendation")}
                </SectionHeading>
                {/* <div
                  dangerouslySetInnerHTML={{ __html: descriptionContent.image }}
                /> */}
                <div
                  className="about-city-content"
                  dangerouslySetInnerHTML={{
                    __html: myRecommendation.childMarkdownRemark.html,
                  }}
                />
              </Section>
            )}
            {thingsYoullNeedToBring && (
              <Section style={{ padding: "20px 0" }}>
                <SectionHeading component="h2">
                  {capitalizeHeading("What you'll need to bring")}
                </SectionHeading>
                {/* <div
                  dangerouslySetInnerHTML={{ __html: descriptionContent.image }}
                /> */}
                <div
                  className="about-city-content"
                  dangerouslySetInnerHTML={{
                    __html: thingsYoullNeedToBring.childMarkdownRemark.html,
                  }}
                />
              </Section>
            )}
            <div className="what-not-to-miss-avoid">
              {whatNotToMiss && (
                <Section
                  style={{
                    padding: "20px",
                    color: "#fff",
                    borderRadius: "20px",
                    marginRight: 10,
                  }}
                  backgroundColor={"#6B8E23"}
                >
                  <SectionHeading
                    component="h2"
                    style={{ color: "#fff !important" }}
                  >
                    <FontAwesomeIcon
                      icon={faStar}
                      style={{ color: "#fff", fontSize: 20, marginRight: 10 }}
                    />{" "}
                    <span style={{ color: "#fff" }}>
                      {capitalizeHeading("What not to miss")}
                    </span>
                  </SectionHeading>
                  <div
                    className="about-city-content"
                    dangerouslySetInnerHTML={{
                      __html: whatNotToMiss.childMarkdownRemark.html,
                    }}
                  />
                </Section>
              )}
              {whatToAvoid && (
                <Section
                  style={{
                    padding: "20px",
                    color: "#fff",
                    borderRadius: "20px",
                  }}
                  backgroundColor={"#ef7b0e"}
                >
                  <SectionHeading
                    component="h2"
                    style={{ color: "#fff !important" }}
                  >
                    <FontAwesomeIcon
                      icon={faWarning}
                      style={{ color: "#fff", fontSize: 20, marginRight: 10 }}
                    />{" "}
                    <span style={{ color: "#fff" }}>
                      {capitalizeHeading("What to avoid")}
                    </span>
                  </SectionHeading>
                  <div
                    className="about-city-content"
                    dangerouslySetInnerHTML={{
                      __html: whatToAvoid.childMarkdownRemark.html,
                    }}
                  />
                </Section>
              )}
            </div>
            <div
              style={{
                padding: 20,
                backgroundColor: "#FDF4EF",
                marginTop: 20,
              }}
            >
              <p style={{ marginTop: 0 }}>Table of Contents</p>
              <div className="article-dropdown">
                <div className="contents-list">
                  {tableOfContents
                    .slice(0, showAll ? undefined : 5) // Use slice to get the top 5 items when showAll is false
                    .map(({ text, id, imgSrc, imgAlt }, index) => (
                      <a href={`#${id}`} key={id}>
                        <div
                          key={id}
                          className="content-item"
                          style={{ display: "flex" }}
                        >
                          {imgSrc && <img src={imgSrc} alt={imgAlt} />}
                          <div dangerouslySetInnerHTML={{ __html: text }} />
                        </div>
                      </a>
                    ))}
                </div>

                {!showAll && (
                  <div className="view-more">
                    <a onClick={handleViewMoreClick}>View More</a>
                  </div>
                )}
              </div>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: formatHtml(
                  content.childMarkdownRemark.html,
                  followLinks?.childMarkdownRemark.rawMarkdownBody
                ),
              }}
            />
            {showDynamicContent && youtubeLink && (
              <iframe
                src={youtubeLink}
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
                style={{
                  maxWidth: "100%",
                  width: "100%",
                  marginTop: 20,
                  aspectRatio: "16 / 9",
                }}
              ></iframe>
            )}
          </div>
          <div className="article-sidebar">
            {/* <div className="article-sidebar-section">
              <SubscribeForm compact />
            </div> */}
            {/* <div className="article-sidebar-section">
              <div>
                <div className="social-media-icons">
                  <a
                    href="https://www.youtube.com/@travel-lingual"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={YoutubeIcon} />
                  </a>
                  <a
                    href="https://linkedin.com/in/james-a-smith-bbb80582"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={LinkedInIcon} />
                  </a>
                  <a
                    href="https://www.tiktok.com/@travellingual"
                    target="_blank"
                  >
                    <img className="social-media-icon" src={TikTokIcon} />
                  </a>
                </div>
              </div>
            </div> */}
            {tourPlan && (
              <TourPlan
                html={content.childMarkdownRemark.html}
                items={tourPlan.childMarkdownRemark.html}
              />
            )}
            {showDynamicContent && viatorSideWidget && (
              <ViatorWidget code={viatorSideWidget.childMarkdownRemark.html} />
            )}
            {showDynamicContent && tiktokSideWidget && (
              <TikTokWidget code={tiktokSideWidget.childMarkdownRemark.html} />
            )}
          </div>
        </div>
      </Section>
      {showDynamicContent && viatorBottomWidget && (
        <Section>
          <ViatorWidget code={viatorBottomWidget.childMarkdownRemark.html} />
        </Section>
      )}
      {showDynamicContent && (
        <Section style={{ padding: 0 }}>
          <FacebookComments location={location} />
        </Section>
      )}
      {/* {article.city && (
        <Section backgroundColor="#f5f5f5">
          <SectionHeading component="h2">
            SEE MORE THINGS TO DO IN {article.city.name.toUpperCase()}
          </SectionHeading>
          <SearchResultCarousel
            slides={
              cityActivities &&
              cityActivities.map((activity) => (
                <SearchResult compact={true} searchResult={activity} />
              ))
            }
          />
        </Section>
      )} */}
      {relatedArticles && relatedArticles.length > 0 && (
        <RelatedArticles relatedArticles={relatedArticles} />
      )}
      {showBanner && (
        <ArticleBanner
          bannerText={bannerText}
          bannerUrl={bannerUrl}
          bannerCtaText={bannerCtaText}
        />
      )}
      <FeaturedIn />
      <Footer bannerShowing={showBanner} />
    </Page>
  );
};

export default ArticleTemplate;

export const Head = ({ data }) => {
  const { title, description, thumbnailImageUrl, createdAt } =
    data.contentfulArticle;
  const location = useLocation();
  return (
    <SEO
      index
      follow
      title={title}
      description={
        description && description.childMarkdownRemark.rawMarkdownBody
      }
      image={thumbnailImageUrl}
      pathname={location.pathname}
      publishedDate={createdAt}
    />
  );
};
