import * as React from "react";
import clsx from "clsx";

import "./Breadcrumbs.css";

export default function Breadcrumbs({ children }) {
  return (
    <div
      className={clsx("breadcrumbs")}
      style={{ fontSize: children.length > 8 && 13 }}
    >
      {children}
    </div>
  );
}
